const URL_PREFIX = 'data:image/svg+xml;charset=UTF-8;base64,';

let clusterIconsCache = {};

export default {
    getClusterURL(color) {
        let cacheKey = `${color}`;
        let url = clusterIconsCache[cacheKey];
        if (!url) {
            url = clusterIconsCache[cacheKey] = URL_PREFIX + btoa(this.getClusterSVG(color));
        }
        return url;
    },

    getClusterSVG(color) {
        const _color = color || '#FFC100';

        return `
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200">
                <g fill="${_color}">
                    <circle r="42" fill-opacity="0.5"/>
                    <circle r="50" fill-opacity="0.5"/>
                    <circle r="60" fill-opacity="0.5"/>
                </g>
            </svg>
        `;
    },
}

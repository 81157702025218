const URL_PREFIX = 'data:image/svg+xml;charset=UTF-8;base64,';

let landmarkIconsCache = {};

export default {
    getMapLandmarkIcon(color, opacity) {
        return {
            iconUrl: this.getLandmarkURL(color, opacity),
            iconSize: [25, 25],
            iconAnchor: [20, 25],
        }
    },

    getLandmarkURL(color, opacity) {
        let cacheKey = `${color}:${opacity}`;
        let url = landmarkIconsCache[cacheKey];
        if (!url) {
            url = landmarkIconsCache[cacheKey] = URL_PREFIX + btoa(this.getLandmarkSVG(color, opacity));
        }
        return url;
    },

    getLandmarkSVG(color, opacity) {
        const _color = color || '#F44336';
        const _opacity = opacity || 0.96;

        return `
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 38 38">
                <style type="text/css">
                    .shadow{
                        fill:url(#SVGID_1_);
                    }
                    .staff{
                        fill:#3A3A3A;
                    }
                    .flag{
                        opacity:${_opacity};
                        fill:${_color};
                    }
                </style>
                <rect x="7" y="36" class="shadow" width="22.4188" height="2"/>
                <rect class="staff" x="29" y="0" width="2" height="35"/>
                <polygon class="flag" points="30,1 10,1 18,10 10,18 30,18 30,1"/>
            </svg>
        `;
    },
}
